<template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="선지급명세서" :topButton="true" :topButtonBottom="250">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview" :zIndex="50">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_goMain" />
              <div slot="nav" class="icon-wrapper" @click="fn_goMain">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '선지급명세서' : '선지급명세서'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt25 mb10">
              <div class="text-blue-box fexTy2">
                <div>
                  <span>서명여부</span>
                  <b class="ml10" :class="{'crTy-orange2' : signYn ==='N', 'crTy-blue3' : signYn === 'Y'}" >{{signYn}}</b>
                </div> 
                <div class="fexTy1" v-if="signYn ==='Y'">
                  <em>|</em><span>서명일시</span><b class="ml10">{{signDtm==='00000000'?'': $bizUtil.dateDashFormat(signDtm)}}</b>
                </div>
              </div>
            </ur-box-container>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="bd-b-Ty1">
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area row-box mt10 mb20">  <!-- 레이블 과 한줄로 -->
                <span class="label-title w100px">지급월</span>
                <mo-month-picker class="full" placeholder="지급월" ref="monthPicker" v-model="targetMonth" @confirmed="fn_selPrpymSpcf"/>
              </ur-box-container>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb200">
              <ur-box-container v-if="APPrpymSpcfListSVO.length > 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
                <mo-list :list-data="APPrpymSpcfListSVO">
                  <template #list-item="{item}">
                    <mo-list-item >
                      <div class="list-item__contents">
                        <div class="list-item__contents__title fexTy3">
                          <span class="name txtSkip fs17rem">{{item.pmtNm}}</span>
                        </div>
                        <div class="list-item__contents__info mt10">
                          <b class="fs16rem crTy-blue3">{{$bizUtil.numberWithCommas(item.pymAmt)}}원</b>
                        </div>
                        <div class="list-item__contents__info mt10">
                          <div>
                            <span class="fs14rem mr10">지급내역</span><span class="crTy-bk1">{{item.pmtCntnt}}</span>
                          </div>
                          <div class="mt4">
                            <span class="fs14rem mr10">지급일자</span><span class="crTy-bk1">{{$bizUtil.dateDashFormat(item.pymYmd)}}</span><em>|</em>
                            <span class="fs14rem mr10">처리자</span><span class="crTy-bk1">{{item.usnam + '(' + item.trtrNm + ')'}}</span>
                          </div>
                          <div class="mt4">
                            <span class="fs14rem mr10">참고번호</span><span class="crTy-bk1">{{item.rfncNo}}</span>
                          </div>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>

              <!-- no-data   -->
              <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
                <mo-list-item ref="expItem">
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span class="ns-ftcr-gray"><p class="mt20">데이터가 존재하지 않습니다.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container> 
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <ur-box-container alignV="start" v-show="monthPickerShowFlag == 0" direction="column" class="ns-btn-relative add-con bottom60">
      <div class="add-box gray-box fs16rem">
        <div class="fexTy3">
          <span>합계</span>
          <span><b class="mr2 fs18rem crTy-blue3">{{$bizUtil.numberWithCommas(totalSum)}}</b>원</span>
        </div>
        <div class="mt10 fs14rem txt-left crTy-bk7" v-if="showNotice"><!-- v-if 서명완료 안내문구 및 버튼 비활성화 처리 -->
          <mo-icon icon="msp-tool-tip" class="mr4"></mo-icon><span>{{noticeMsg}}</span>
        </div>
      </div>
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="disSignbtn" @click="fn_SignClick">서명{{signYn === 'Y' ? '완료': ''}}</mo-button> <!-- disabled="" 서명완료 안내문구 및 버튼 비활성화 처리 -->
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
   import moment from 'moment'
   import DateUtil from '@/ui/sp/common/exDateUtils'
   import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
   import Msg from '@/systems/webkit/msg/msg'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP121M",
    screenId: "MSPAP121M",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
      
      let month = DateUtil.fn_AddMonth(moment(new Date().toISOString()).format('YYYYMMDD'), -1)
      this.targetMonth = [month.substring(0,4), month.substring(4,6)]
    },
    mounted () {
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
      
      this.fn_selPrpymSpcf()

      this.isMounted = true
    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        APPrpymSpcfListSVO: [],
        noticeMsg: '',
        lv_confirmMsg: '',
        signDtm: '00000000',
        signYn: '',
        showNotice: false,
        disSignbtn: false,
        isEmpty: true,
        isDetailSearch: false,
        targetMonth: ['',''],
        selectOrigin: null,
        totalSum: 0,
        isCreate: true,
        isMounted: false
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      monthPickerShowFlag() {
        if(this.isMounted){
          return this.$refs.monthPicker.showFlag
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : FC의 선지급 조회 시 유효성 체크
      ******************************************************************************/
      fn_Validation () {
        let lv_RetVal = true
        let targetFinYm = this.targetMonth[0] + this.$bizUtil.lpad(this.targetMonth[1], 2, '0')
        if (targetFinYm === undefined || targetFinYm.trim() === '' || targetFinYm === '000000') {
          this.getStore('confirm').dispatch('ADD', '지급월을 선택해주세요')
          lv_RetVal = false
          return lv_RetVal
        }
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_selPrpymSpcf
      * 설명       : FC의 선지급 명세서를 조회한다.
      ******************************************************************************/
      fn_selPrpymSpcf () {
        let lv_RetVal = this.fn_Validation()
        if (lv_RetVal) {
          var lv_Vm = this
          var trnstId = 'txTSSAP50S1'
          lv_Vm.APPrpymSpcfListSVO = []

          var pParam = {
            cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
            finYm: lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0')
          }

          lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
            console.log('==============>>> ', response)

            if ( response.body != null && response.body.aPPrpymSpcfSVOList != null) {
              let responseList = response.body.aPPrpymSpcfSVOList
              responseList.forEach((item) => {
                lv_Vm.APPrpymSpcfListSVO.push(item)
              })

              lv_Vm.signDtm = response.body.signDtm // 서명일시
              lv_Vm.signYn = response.body.signYn // 서명여부
              lv_Vm.totalSum = response.body.totalSum // 합계액

              if (lv_Vm.APPrpymSpcfListSVO.length === 0) { // 조회 데이터가 없는 경우 : 하단 문구 노출X / 서명버튼 비활성화
                lv_Vm.showNotice = false
                lv_Vm.disSignbtn = true
              } else if (response.body.finYm < '202103') { // 2021년 02월까지의 데이터인 경우 : 하단 문구 노출 O / 서명버튼 비활성화
                lv_Vm.showNotice = true
                lv_Vm.disSignbtn = true
                lv_Vm.noticeMsg = '2021-02월까지는 출력물로 수기서명 진행'
              } else {
                if (lv_Vm.signYn === 'Y') { // 서명 완료된 데이터 : 하단 문구 노출 O / 서명버튼 비활성화
                  lv_Vm.showNotice = true
                  lv_Vm.noticeMsg = '해당 선지급 내역에 대하여 이미 서명하셨습니다.'
                  lv_Vm.disSignbtn = true
                } else { // 서명 미완료 데이터 : 하단 문구 노출 X / 서명버튼 활성화 / 서명여부 'N'
                  lv_Vm.showNotice = false
                  lv_Vm.disSignbtn = false
                  lv_Vm.signYn = 'N'
                }
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          }).catch(function (error) {
            window.vue.error(error)
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_insPrpymSpcfSign
      * 설명       : FC의 선지급 명세서에 서명한다.
      ******************************************************************************/
      fn_SignClick () {
        let selectMonth = this.targetMonth[0] + this.$bizUtil.lpad(this.targetMonth[1], 2, '0')
        let letYm = new Date().toISOString().substring(0, 7).replace('-', '')
        
        if (selectMonth === letYm) {
          this.lv_confirmMsg = '※ 주의 : 당월 선지급 내역입니다\n확정된 내역은 익월 3영업일부터 확인 가능합니다\n해당 선지급 내역에 대하여\n이상없음을 확인합니다.'
        } else {
          this.lv_confirmMsg = '해당 선지급 내역에 대하여 이상없음을 확인합니다.'
        }

        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              //single: true,
              title: "",
              content: this.lv_confirmMsg,
              title_pos_btn: "예",
              title_neg_btn: "아니오"
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(lv_AlertPop)
                this.fn_insPrpymSpcfSign()
              },
              onPopupClose: () => {
                this.$bottomModal.close(lv_AlertPop)
              }
            }
          })
          
      },
      /******************************************************************************
      * Function명 : fn_insPrpymSpcfSign
      * 설명       : FC의 선지급 명세서에 서명한다.
      ******************************************************************************/
      fn_insPrpymSpcfSign () {
        var lv_Vm = this
        var trnstId = 'txTSSAP50I1'
        let nowDate = this.$bizUtil.getDateToString(new Date(), '')

        var pParam = {
          cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
          finYm: lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0'),
          signDtm: nowDate
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            let responseList = response.body
            let msgScCd = responseList.msgScCd
            let msgCntnt = responseList.msgCntnt

            if (msgScCd === 'S') {
              lv_Vm.fn_selPrpymSpcf()
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', msgCntnt)
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      }
    }
  };
</script>
<style scoped>
</style>